<template>
  <div class="addTenantManagement" v-loading="isLoading">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      :submitError="submitError"
      @update="update"
    >
      <template>
        <el-form-item
          label="区"
          :rules="[{ required: true, message: '请选择区', trigger: 'blur' }]"
          prop="areaName"
        >
          <v-select
            :disabled="isEdit"
            clearable
            @change="districtCodeChange"
            @clear="districtCodeclear"
            :options="districtListOptions"
            v-model="form.areaName"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="街道"
          :rules="[{ required: true, message: '请选择街道', trigger: 'blur' }]"
          prop="streetName"
        >
          <v-select
            :disabled="isEdit"
            clearable
            @change="streetIdChange"
            @clear="streetIdClear"
            :options="streetListOptions"
            v-model="form.streetName"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="社区"
          :rules="[{ required: true, message: '请选择社区', trigger: 'blur' }]"
          prop="districtName"
        >
          <v-select
            :disabled="isEdit"
            clearable
            @clear="structuringIdClear"
            :options="structuringOptions"
            v-model="form.districtName"
          ></v-select>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in communityName"
          :key="index"
          label="小区"
          :rules="[
            { required: true, message: '请输入菜单名称', trigger: 'blur' },
          ]"
        >
          <v-input placeholder="请输入" v-model="item.text"></v-input>
          <v-button
            class="danger"
            type="danger"
            text="删除"
            @click="deleteCommunityName(item, index)"
          ></v-button>
          <v-button
            v-if="communityName.length - 1 == index"
            class="danger"
            text="添加"
            @click="addTenant"
          ></v-button>
        </el-form-item>
      </template>
    </form-panel>
  </div>
</template>

<script>
import {
  addDTenant,
  modifyDTenant,
  deleteVillageCheck,
  getSpaceLinkList,
  getStreetCommunityListUrl,
} from "./api.js";
export default {
  name: "addTenantManagement",
  data() {
    return {
      isLoading: false,
      isEdit: false,
      parentId: "",
      form: {
        areaName: "", //区
        streetName: "", //街道
        districtName: "", //社区
        villageNameList: [], //小区
      },
      districtListOptions: [], //区级列表
      streetListOptions: [], //街道列表
      structuringOptions: [], //社区列表
      firstIn: false,
      firstIn1: false,
      submitConfig: {
        queryUrl: getSpaceLinkList,
        queryMethod: "get",
        submitUrl: null,
      },
      communityName: [
        {
          text: "",
        },
      ],
    };
  },
  computed: {},
  async created() {
    await this.getStreetCommunityList();
    const { parentId, areaName, districtName, streetName } = this.$route.query;
    if (parentId !== undefined) {
      this.parentId = parentId;
      this.isEdit = true;
      this.form.areaName = areaName;
      this.form.streetName = streetName;
      this.form.districtName = districtName;
      this.submitConfig.submitUrl = modifyDTenant;
      const params = {
        specifyCollectionTypes: "10",
        parentId: parentId,
      };
      this.$refs.formPanel.getData({ ...params });
    } else {
      this.submitConfig.submitUrl = addDTenant;
    }
    this.$setBreadList(parentId ? "编辑" : "新增");
  },

  mounted() {
    this.firstIn = true;
    this.firstIn1 = true;
  },
  methods: {
    //获取区级列表
    async getStreetCommunityList() {
      this.isLoading = true;
      this.districtListOptions = [];
      let res = await this.$axios.get(`${getStreetCommunityListUrl}`);
      if (res.code === 200) {
        this.isLoading = false;
        this.allList = res.data;
        res.data.forEach((item) => {
          item.label = item.districtName;
          item.value = item.districtName;
          item.list.forEach((item1) => {
            item1.label = item1.streetName;
            item1.value = item1.streetName;
            item1.list.forEach((item2) => {
              item2.label = item2.structuringName;
              item2.value = item2.structuringName;
            });
          });
          this.districtListOptions.push(item);
        });
        this.districtListOptions.forEach((item) => {
          if (item.value == this.form.areaName) {
            this.streetListOptions = item.list;
            this.streetListOptions.forEach((item1) => {
              if (item1.value == this.form.streetName) {
                this.structuringOptions = item1.list;
              }
            });
          }
        });
      }
    },
    //选择区级
    districtCodeChange(value) {
      if (!this.firstIn) {
        this.form.streetName = null;
        this.form.districtName = null;
      } else {
        this.firstIn = false;
      }
      //选择区级change
      this.streetListOptions = [];
      this.districtListOptions.forEach((item, index) => {
        if (item.districtName == value) {
          this.streetListOptions = item.list;
        }
      });
    },
    //清空区级内容
    districtCodeclear() {
      this.form.areaName = null;
      this.form.streetName = null;
      this.form.districtName = null;
    },
    //选择街道
    streetIdChange(value) {
      if (!this.firstIn1) {
        this.form.districtName = null;
      } else {
        this.firstIn1 = false;
      }
      //选择街道change
      this.structuringOptions = [];
      this.streetListOptions.forEach((item, index) => {
        if (item.streetName == value) {
          this.structuringOptions = item.list;
        }
      });
    }, //清空街道内容
    streetIdClear() {
      this.form.streetName = null;
      this.form.districtName = null;
    },
    //清空社区内容
    structuringIdClear() {
      this.form.districtName = null;
    },
    //删除小区
    deleteCommunityName(item, index) {
      if (this.isEdit) {
        const params = {
          villageId: item.code,
        };
        this.$axios.get(deleteVillageCheck, { params }).then((res) => {
          if (res.code == 200) {
            this.communityName.splice(index, 1);
            this.$message.success("删除成功");
          }
        });
      } else {
        this.communityName.splice(index, 1);
      }
    },
    //添加数据
    addTenant() {
      const params = {
        text: "",
      };
      this.communityName.push(params);
    },
    //提交前
    submitBefore() {
      this.isLoading = true;
      if (this.isEdit) {
        this.form = null;
        const villageNameList = this.communityName.map((item) => item.text);
        const params = {
          districtId: this.parentId,
          villageNameList,
        };
        this.form = { ...params };
      } else {
        this.form.villageNameList = this.communityName.map((item) => item.text);
      }
      return true;
    },
    submitSuccess() {
      this.isLoading = false;
      return true;
    },
    submitError() {
      this.isLoading = false;
      return true;
    },
    update(data) {
      this.communityName = data.list;
    },
  },
};
</script>
<style lang="less" scoped>
.addTenantManagement {
  height: 100%;
  .danger {
    margin-left: 10px;
  }
}
</style>
