//租户列表小区
const getDTenantWithVList = `/gateway/blade-system/tenant/getDTenantWithVList`;
//删除社区租户
const deleteDTenant = `/gateway/blade-system/tenant/deleteDTenant`;
//新增保持
const addDTenant = `/gateway/blade-system/tenant/addDTenant`;
//编辑保存
const modifyDTenant = `/gateway/blade-system/tenant/modifyDTenant`;
//删除小区
const deleteVillageCheck = `/gateway/blade-system/tenant/deleteVillageCheck`;
//编辑社区租户初始化查询（获取下辖小区列表）
const getSpaceLinkList = `/gateway/hc-space/space/getSpaceLinkList`;
// 获取区县/街道/社区列表
const getStreetCommunityListUrl = `/gateway/hc-serve/manageapi/place/get-street-community-list`;
// 根据id获取短信通道配置
const getSmsConfigById = `/gateway/blade-system/tenant/getSmsConfigById`;
// 根据id获取短信通道配置
const updateSmsConfig = `/gateway/blade-system/tenant/updateSmsConfig`;
// 根据id获取运营配置
const getTenantConfigById = `/gateway/blade-system/tenant/getTenantConfigById`;
// 运营配置
const updateTenantConfig = `/gateway/blade-system/tenant/updateTenantConfig`;
export {
  getDTenantWithVList,
  deleteDTenant,
  addDTenant,
  modifyDTenant,
  deleteVillageCheck,
  getSpaceLinkList,
  getStreetCommunityListUrl,
  getSmsConfigById,
  updateSmsConfig,
  updateTenantConfig,
  getTenantConfigById,
};
